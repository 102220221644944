import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Welkom op Free4me.nl met de beste aanbiedingen</h1>
     <p><a href="https://click.picnic.nl/f/a/pnsOZX1JJm_gHvPzFqjWsw~~/AAAHagA~/RgRoCf5pP0SPaHR0cHM6Ly9waWNuaWMuYXBwL25sL2xpbmsvc3RvcmUvcHJvZmlsZS9pbnZpdGUtZnJpZW5kP3V0bV9zb3VyY2U9cGljbmljJnV0bV9tZWRpdW09ZW1haWxfc3RydWN0dXJhbCZ1dG1fY2FtcGFpZ249bWdtX2NhbXBhaWduJmxpZD1jaHMydzdhYXEyMXdXBXNwY2V1QgpmH2l5J2bXOlxQUhVnZWVydEBnZWVydGRlZ3JhYWYubmxYBAAAA18~" target="_blank" rel="Pikkepik">Pikkepik 10 euro korting</a></p>
    <p><a href="https://pr.tn/ref/NDPMJRTXE300" target="_blank" rel="ProtonMail">ProtonMail</a></p>
    <p><a href="https://www.oculus.com/referrals/link/GraafG/" target="_blank" rel="Oculus Quest">Oculus Quest</a></p>
    <p><a href="https://www.banggood.com/bang/?tt=16956_613934_69811_&amp;r=" target="_blank" rel="sponsored nofollow noopener noreferrer">Best Bang For Your Buck</a></p>
    <p><a href="https://tc.tradetracker.net/?c=27&amp;m=631409&amp;a=69811&amp;r=&amp;u=" target="_blank" rel="sponsored nofollow noopener noreferrer"><img src="https://ti.tradetracker.net/?c=27&amp;m=631409&amp;a=69811&amp;r=&amp;t=html" alt="" width="125" height="125" border="0" /></a></p>
    <p><a href="https://i.soreto.com/cea_nl/g/E1l8RnAh2 ">C&A</a><br /><br /></p>
    <p><a href="https://vriendendeal.simyo.nl/sim-only/hKq3d5">Simyo</a><br /><br /></p>
    <p><a href="https://mgmco.nl/tofaho/pp https://www.oneplus.com/ie/referral?utm_source=referral-program&amp;utm_medium=email">Charlie Temple</a></p>
    <p><a href="https://www.agoda.com/partners/partnersearch.aspx?pcs=1&amp;cid=1832000&amp;hl=en&amp;hid=1618062" target="_blank" rel="nofollow noopener noreferrer">Agoda<br />Booking.com met 15 euro korting<br />ValkExclusief komt u in aanmerking voor de beloning van €5.<br /></a></p>
    <h2>Computers</h2>
    <p><a href="https://mega.nz/aff=9UrnYiJfYq8">MEGA<br /></a><a href="http://www.americanexpress.nl/introduceren/gERRIDpovQ?CPID=999999548" target="_blank" rel="noopener noreferrer">AMEX</a></p>
    <p><a href="http://www.alternate.nl/tt/?tt=904_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Alternate.nl</a><br /> <a href="http://www.centralpoint.nl/tracker/index.php?tt=534_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Centralpoint.nl</a><br /> <a href="http://www.computerpirates.com/tradetracker/?tt=181_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">ComputerPirates.com</a><br /> <a href="http://www.informatique.nl/tt/?tt=537_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Informatique.nl</a><br /> <a href="http://www.paradigit.nl/tt/index.aspx?tt=5043_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Paradigit.nl</a><br /> <a href="http://www.yorcom.nl/computing/?tt=4837_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Yorcom.nl</a><br /> <a href="http://tc.tradetracker.net/?c=1544&amp;m=12&amp;a=69811&amp;u=%2F" target="_blank" rel="nofollow noopener noreferrer">WiFiShop.nl</a></p>
    <h2>Electronica</h2>
    <p><a href="https://tc.tradetracker.net/?c=15640&amp;m=12&amp;a=69811&amp;u=%2F" target="_blank" rel="nofollow noopener noreferrer">Ali Express</a><br /> <a href="http://www.conrad-electronic.nl/tt/?tt=920_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Conrad.nl</a><br /> <a href="http://tc.tradetracker.net/?c=16242&amp;m=12&amp;a=69811&amp;u=%2F" target="_blank" rel="nofollow noopener noreferrer">DealeXtreme.com</a></p>
    <h2>Hosting</h2>
    <p><a href="http://www.yourhosting.nl/tradetracker-redirect.html?campaignID=402&amp;materialID=12&amp;affiliateID=69811&amp;redirectURL=%2F" target="_blank" rel="nofollow noopener noreferrer">Yourhosting.nl</a><br /> <a href="https://www.vimexx.nl/affiliate/set/r8zth5nlqobbhp32fm41" target="_blank" rel="nofollow noopener noreferrer">Vimexx</a><br /> <a href="http://www.argeweb.nl/websitehosting/?tt=6289_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Argeweb.nl</a></p>
    <h2>Speelgoed</h2>
    <p><a href="http://tc.tradetracker.net/?c=18986&amp;m=12&amp;a=69811&amp;u=https%3A%2F%2Fwww.bartsmit.com%2Fnl%2Fbsnl" target="_blank" rel="nofollow noopener noreferrer">Bartsmit.com</a> <a href="http://tc.tradetracker.net/?c=18987&amp;m=12&amp;a=69811&amp;u=%2F" target="_blank" rel="nofollow noopener noreferrer">Intertoys.nl</a> <a href="http://www.nedgame.nl/tradetracker/?tt=558_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Nedgame.nl</a></p>
    <h2>Software</h2>
    <p><a href="http://www.invender.nl/ttiv/index.php?tt=352_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Invender.nl</a><br /> <a href="http://www.magix.com/ap/tradetracker/?tt=2074_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">Magix.com</a></p>
    <h2>Opleiding</h2>
    <p><a href="http://tc.tradetracker.net/?c=1021&amp;m=12&amp;a=69811&amp;u=%2F" target="_blank" rel="nofollow noopener noreferrer">LOI.nl</a> <a href="http://www.nti.nl/studeren/affiliates/trt/index.php?tt=516_12_69811_&amp;r=%2F" target="_blank" rel="nofollow noopener noreferrer">NTI.nl</a> <a href="http://tc.tradetracker.net/?c=214&amp;m=1948&amp;a=69811&amp;r=&amp;u=" target="_blank" rel="nofollow noopener noreferrer">Studieplan</a></p>
    <h1><a href="https://www.g2play.net/?r=8427&amp;___store=g2play_net_english">G2Play</a></h1>
    <p><br /><a href="https://www.humblebundle.com/subscription?refc=98I93W">Humble Bundle</a></p>
    <h2>Nieuwsgroepen</h2>
    <p><b>Supernews</b><br /> <a href="http://www.supernews.com/?i=ZBBrqKGfCec=" target="_blank" rel="nofollow noopener noreferrer">Supernews</a> <a href="http://ssqt.co/mQe6Zpn">tweaknews </a></p>
    
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p>

  </Layout>
)

export default IndexPage
